// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rh d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rj d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_rk d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rl d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rm d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rn d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rp d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rq x_rq";
export var heroExceptionNormal = "s_rr x_rr";
export var heroExceptionLarge = "s_rs x_rs";
export var Title1Small = "s_rt x_rt x_sf x_sg";
export var Title1Normal = "s_rv x_rv x_sf x_sh";
export var Title1Large = "s_rw x_rw x_sf x_sj";
export var BodySmall = "s_rx x_rx x_sf x_sy";
export var BodyNormal = "s_ry x_ry x_sf x_sz";
export var BodyLarge = "s_rz x_rz x_sf x_sB";